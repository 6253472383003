import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import axiosStudents from "../../../axios/BUAP/students";
import GetToken from "../../../Shared/GetToken";
import Message from "../../../components/BUAP/Message";
import { connect } from "react-redux";

const Blocked = (props) => {
  const [msgcode, setMsgCode] = useState(0);
  useEffect(() => {
    axiosStudents
      .get("CheckBlockKind/" + props.stuno, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        if (response.data.teacherEvalation) {
          setMsgCode(39);
        } else if (response.data.financialBlock) {
          setMsgCode(34);
        } else if (response.data.libraryBlock) {
          setMsgCode(35);
        } else if (response.data.loanBlock) {
          setMsgCode(36);
        } else if (response.data.registrationBlock) {
          setMsgCode(37);
        } else if (response.data.transcriptBlock) {
          setMsgCode(45);
        }
      });
  }, [props.stuno]);

  return (
    <Container>
      <Message msgCode={msgcode} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    stuno: state.stuno.studentno,
  };
};
export default connect(mapStateToProps)(Blocked);
