import React, { useEffect, useState } from "react";
import Semester from "../../../../components/Shared/Semester";
import Container from "@material-ui/core/Container";
import NameAndNum from "../../../../components/BUAP/Student/Information/NameAndNum";
import Header from "../../../../components/Shared/Header";
import RegisteredCourses from "../../../Registration/RegisteredCourses";
import ScheduleTerm from "../../../../components/ScheduleComponent/ScheduleTerm";
import ScheduleComponent from "../../../../components/ScheduleComponent/ScheduleComponent";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "../../../../axios/BUAP/registration";
import { connect } from "react-redux";
import GetToken from "../../../../Shared/GetToken";
import classes from "./Schedule.module.css";
import SpinnerBootstrap from "../../../../components/UI/Spinner/Spinner";
import Blocked from "../Blocked";
import Ramadan from "../../../../components/Layout/Ramadan";

const Schedule = (props) => {
  const [term, setTerm] = useState(0);
  const [header, setHeader] = useState("");
  const [avilableTerms, setAvilableTerms] = useState([{}]);
  const [waitData, setWaitData] = useState(true);
  const [blocked, setBlocked] = useState(false);

  useEffect(() => {
    axios
      .get("ScheduleTerms/" + props.studentdegree, {
        headers: { Authorization: GetToken("token") },
      })
      .then((response) => {
        setAvilableTerms(response.data.terms);
        setTerm(response.data.defaultTerm);
        setHeader(
          <Semester sem={response.data.defaultTerm} txt="Schedule for " />
        );
        setWaitData(false);
        if (response.data.statusCode === 405) {
          setBlocked(true);
        }
      })
      .catch(function (error) {
        setWaitData(false);
        setBlocked(true);
      });
  }, [props.studentdegree]);

  const handleChange = (event) => {
    setTerm(event.target.value);
    setHeader(
      <Semester sem={event.target.value.toString()} txt="Schedule for " />
    );
  };

  return (
    <React.Fragment>
      {!blocked ? (
        <React.Fragment>
          {waitData ? (
            <SpinnerBootstrap />
          ) : (
            <Container>
              {props.usertype === "0" ? <NameAndNum /> : null}
              <Grid style={{ paddingTop: 20 }}>
                <Header header={header} />
              </Grid>
              <Grid item xs={12} sm={12} style={{ textAlign: "center" }}>
                <Paper className="hideonprint" style={{ boxShadow: "none" }}>
                  <FormControl>
                    <InputLabel id="term-select-label">Semester</InputLabel>
                    <Select
                      labelId="term-select-label"
                      id="term-select"
                      value={term}
                      onChange={handleChange}
                      classes={{
                        root: classes.root,
                      }}
                    >
                      <MenuItem value={0}></MenuItem>
                      {avilableTerms.map((semester, index) => {
                        return (
                          <MenuItem key={index} value={semester}>
                            <Semester sem={semester} />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Paper>
              </Grid>

              <RegisteredCourses sem={term} />
              {/* {term == 20251 ? <Ramadan /> : null} */}

              <div style={{ paddingTop: "12px" }} className="hideonprint">
                <ScheduleTerm title="To view teacher name, point the mouse over the course name." />{" "}
              </div>

              <ScheduleComponent sem={term} />
            </Container>
          )}
        </React.Fragment>
      ) : (
        <Blocked />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    studentdegree: state.studeg.studentdegree,
    usertype: state.utype.usertype,
  };
};

export default connect(mapStateToProps)(Schedule);
