import React, { useRef } from "react";
import Container from "@material-ui/core/Container";
import NameAndNum from "../../../../components/BUAP/Student/Information/NameAndNum";
import Transcripts from "../../../../components/BUAP/Student/Transcript/Transcripts/Transcripts";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import { Watermark } from "@hirohe/react-watermark";
import classes from "./transcript.module.css";
const Transcript = () => {
  const ref = useRef();
  return (
    <Container>
      <div style={{ textAlign: "right" }}>
        <ReactToPrint
          bodyClass="print-agreement"
          content={() => ref.current}
          trigger={() => <Button type="primary">Print</Button>}
        />
      </div>
      <div ref={ref}>
        <NameAndNum />
        <p
          className="showonprint"
          style={{
            fontSize: "18px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {" "}
          Transcript
          <br />
          Unofficial copy
        </p>
        <div className="showonprint">
          <Watermark
            text="&nbsp;Unofficial&nbsp;"
            textSize={40}
            gutter={1}
            lineHeight="1.5rem"
            fontFamily="Roboto"
            opacity={0.4}
            rotate={-45}
          >
            <Transcripts />
          </Watermark>
        </div>
        <div className={classes.withoutprint}>
          <Transcripts />
        </div>
      </div>{" "}
    </Container>
  );
};
export default Transcript;
